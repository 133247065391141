import "./VideoTransition.css";
import { ConferenceMode, SessionStatus } from "../../shared/enums";
import FinishingComponent from "components/FinishComponent/FinishingComponent";
import { AwaitingComponent } from "components/AwaitingComponent/AwaitingComponent";

export default function VideoRoomTransition(props: { mode: ConferenceMode; roomStatus: SessionStatus }) {
  return (
    <div>
      {props.mode === ConferenceMode.OnlyAudio && (
        <div className='audioOnlyConference'>Conferência via áudio habilitada</div>
      )}
      {props.mode === ConferenceMode.OnlyVideo && (
        <div className='videoOnlyConference'>Conferência via vídeo habilitada</div>
      )}
      {props.roomStatus === SessionStatus.Closed && <FinishingComponent />}
      {props.roomStatus === SessionStatus.Awaiting && <AwaitingComponent />}
    </div>
  );
}
