import axios from 'axios';

import { MICRO_SERVICES_HOST, SERVICE_URL, APP_DOMAIN } from 'shared/constants';

const managerHost = axios.create({
  baseURL: `${MICRO_SERVICES_HOST}/${SERVICE_URL}`,
});

const legacyHost = axios.create({
  baseURL: APP_DOMAIN,
});

managerHost.interceptors.response.use(
  (response) => {
    console.info('[http.interceptors]', response.data);
    return response;
  },
  (error) => errorCallback(error)
);

legacyHost.interceptors.response.use(
  (response) => {
    console.info('[http.interceptors]', response.data);
    return response;
  },
  (error) => errorCallback(error)
);

function errorCallback(error: any) {
  const responseAsFailback =
    error.status != 409
      ? Promise.reject(error)
      : Promise.resolve({ token: undefined });

  console.info('[http.interceptors : failback]', responseAsFailback);
  return responseAsFailback;
}

export { managerHost, legacyHost };
