import { useEffect, useState } from "react";

export default function useOSNetwork() {
  const [isOnline, setIsOnline] = useState(navigator.onLine);

  // TODO: introduce lifecycles -> session | publisher | (system) network|memory|cpu
  // TODO: include: changedAt | isOnline | rtt | type | downLink | downLinkMax | saveData | effectiveType
  // TODO: navigator.connection ( android only ) ->  navigator.mozConnection || navigator.webkitConnection;

  useEffect(() => {
    const connection = navigator.connection;
    if (connection) {
      // TODO: send to dahsboard
      console.warn({
        rtt: connection.rtt,
        type: connection.type,
        saveData: connection.saveData,
        downLink: connection.downlink,
        downLinkMax: connection.downlinkMax,
        effectiveType: connection.effectiveType,
      });
    }

    const handleStatusChange = () => {
      setIsOnline(navigator.onLine);
      console.info(`[useOSNetwork] network has changed : online ${navigator.onLine}`);
    };

    window.addEventListener("online", handleStatusChange);
    window.addEventListener("offline", handleStatusChange);

    return () => {
      window.removeEventListener("online", handleStatusChange);
      window.removeEventListener("offline", handleStatusChange);
    };
  }, [isOnline]);

  return { isOnline };
}
