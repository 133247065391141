import { IDeviceControl } from "./interfaces";

export const RELOAD_TIMEOUT_MS = 5000;
export const DEFAULT_WIDTH_VIDEO_RESOLUTION = 320;
export const DEFAULT_HEIGHT_VIDEO_RESOLUTION = 240;

export const APP_DOMAIN = process.env.REACT_APP_DOMAIN;
export const STORAGE_HOST = process.env.REACT_APP_STORAGE_HOST;
export const SERVICE_URL = process.env.REACT_APP_MANAGER_SERVICE;
export const ENVIRONMENT_CONTEXT = process.env.REACT_APP_ENVIRONMENT;
export const MICRO_SERVICES_HOST = process.env.REACT_APP_MICRO_SERVICES_HOST;
export const NETWORK_MONITOR_TIME = Number(process.env.REACT_APP_NETWORK_MONITOR_TIME) * 1000;

export const DEVICE_CONTROLS: IDeviceControl = {
  activeAudio: true,
  activeVideo: true,
  activeRecord: false,
};

// TODO: default option
export const DEFAULT_DEVICE_OPTIONS = {
  resolution: `${DEFAULT_WIDTH_VIDEO_RESOLUTION}x${DEFAULT_HEIGHT_VIDEO_RESOLUTION}`,
  audioSource: undefined,
  videoSource: undefined,
  publishAudio: true,
  publishVideo: true,
  frameRate: 30,
  insertMode: "APPEND",
};

// TODO: audio only option
export const AUDIO_ONLY_DEVICE_OPTIONS = {
  audioSource: undefined,
  videoSource: false,
  publishAudio: true,
  publishVideo: false,
};

// TODO: video only option
export const VIDEO_ONLY_DEVICE_OPTIONS = {
  audioSource: false,
  videoSource: undefined,
  publishAudio: false,
  publishVideo: true,
};
