import { useEffect, useState } from 'react';


export function useMicrophoneDevices() {
  
  const [defaultMicrophone, setDefaultMicrophone] = useState<MediaDeviceInfo|number>(null!);
  const [availableMicrophones, setAvailableMicrophones] = useState<MediaDeviceInfo[]>([]);
 
  function filterUsableDevice(exp: RegExp, device: MediaDeviceInfo) {
    return device.label.match(exp) && device.kind === 'audioinput';
  }

  useEffect(()=>{
    const localMicDevice = async()=>{
      if(!defaultMicrophone){

        try { 
          await navigator.mediaDevices.getUserMedia({
            audio: true,
          });
  
        } catch (error) {
          console.log(error)
        }

        const devices = await navigator.mediaDevices.enumerateDevices();
        const micDevices = devices.filter((device) => filterUsableDevice(/^.{4,35}/i, device));

        console.log({devices, micDevices});

        setAvailableMicrophones(micDevices);

        if(micDevices.length > 0){
          setDefaultMicrophone(micDevices[0]);
        }else{
          setDefaultMicrophone(0);
        }
      }
    }

    localMicDevice();
  },[defaultMicrophone]);

  return {
    defaultMicrophone,
    availableMicrophones
  };
}
