import "./Participant.css";

import { MediaStreamComponent } from "../MediaStreamComponent/DisplayMediaStream";
import { Publisher } from "openvidu-browser";
import { Roles } from "../../shared/enums";

export default function PublisherComponent(props: { publisher: Publisher }) {
  return (
    <div className='local-media'>
      {props.publisher && (
        <MediaStreamComponent id={"local-media"} title={Roles.Analyst} mediaStream={props.publisher} />
      )}
    </div>
  );
}
