import { AppBar, Toolbar } from "@material-ui/core";
import defaultLogo from "../assets/images/planetun.svg";

import { useState } from "react";
import "./Header.css";

export default function Header() {
  const [logo, setLogo] = useState(defaultLogo);

  return (
    <AppBar
      position='static'
      elevation={0}
      style={{ background: "transparent", boxShadow: "none", zIndex: "1" }}>
      <Toolbar>
        <img alt='Planetun' className='logo' src={logo} />
      </Toolbar>
    </AppBar>
  );
}
