import { RELOAD_TIMEOUT_MS, APP_DOMAIN } from "shared/constants";

import { Roles } from "shared/enums";
import { legacyHost } from "./index";

export async function finalizeSession(role: Roles, token: string) {
  const response = await legacyHost.get(
    `/apiToken.cfm?action=finalizaAtendimento&token=${token}&user=${role}`
  );
  return response;
}

export async function getTokenInformation(token: string) {
  const response = await legacyHost.post(`/apiToken.cfm?action=getDadosCliente&token=${token}`);

  return response.data;
}

export async function saveMessageIntoHistory(payload: any, token: string) {
  const response = await legacyHost.post(
    `/apiToken.cfm?action=registraHistorico&token=${token}`,
    payload,
    {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
      },
    }
  );

  return response;
}

export function reloadContext(token: string) {
  setTimeout(() => {
    window.parent.location.href = `${APP_DOMAIN}/apiToken.cfm?action=redirectAtendimento&token=${token}`;
  }, RELOAD_TIMEOUT_MS);
}
