import { managerHost } from "./index";

export async function createSession(sessionId: string, customerId: string) {
  const response = await managerHost.post("/sessions", { sessionId, customerId });
  const { connection } = response.data;

  return connection;
}

export async function startRecording(sessionId: string) {
  return managerHost.post("/recordings/start", {
    sessionId,
  });
}

export async function stopRecording(recordingId: string) {
  return managerHost.post("/recordings/stop", {
    recordingId,
  });
}
