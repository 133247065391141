import { useEffect, useState } from "react";

import { BackgroundType } from "shared/enums";
import { IconButton, Grow, CircularProgress, makeStyles } from "@material-ui/core";
import { BlurOn, Block, AddPhotoAlternate, Person } from "@material-ui/icons";

import "./Buttons.css";
import { deepOrange } from "@material-ui/core/colors";

const useStyles = makeStyles((theme) => ({
  fabProgress: {
    color: deepOrange[500],
    width: "1.6em",
    height: "1.6em",
    position: "absolute",
    zIndex: 1,
  },
}));

export default function FilterButton(props: {
  setIsActiveFilter: any;
  isActiveFilter: boolean;
  applyVirtualFilter: any;
  removeVirtualFilter: any;
  backgroundOptions: string[];
}) {
  const classes = useStyles();
  const [url, setUrl] = useState<string>();
  const [loading, setLoading] = useState(false);
  const [isOpenDialog, setIsOpenDialog] = useState(false);

  function onToggleDialog(e?: any) {
    console.log(e);
    setIsOpenDialog(!isOpenDialog);
  }

  async function executeFilter(options: any, type: BackgroundType) {
    setLoading(true);

    console.log({ isActiveFilter: props.isActiveFilter });

    if (props.isActiveFilter) {
      await props.removeVirtualFilter();
    }
    await props.applyVirtualFilter(options, type);
    props.setIsActiveFilter(true);
    setLoading(false);
  }

  useEffect(() => {
    if (!url && props.backgroundOptions) {
      setUrl(props.backgroundOptions[0]);
    }
  }, [props.backgroundOptions]);

  return (
    <div className='filters'>
      <IconButton
        title='Image Filter'
        color='primary'
        className='navButton'
        onClick={onToggleDialog}
        style={{ backgroundColor: "orange", width: "1.5em", height: "1.5em" }}>
        <Person></Person>
        {loading && <CircularProgress size={48} className={classes.fabProgress} />}
      </IconButton>
      {isOpenDialog && (
        <>
          <Grow in={isOpenDialog} style={{ transformOrigin: "0 0 0" }} {...(isOpenDialog ? { timeout: 400 } : {})}>
            <IconButton
              title='Image Filter'
              color='primary'
              className='navButton'
              disabled={loading}
              style={{ width: "1.5em", height: "1.5em" }}
              onClick={() => executeFilter({ url }, BackgroundType.Image)}>
              <AddPhotoAlternate></AddPhotoAlternate>
            </IconButton>
          </Grow>
          <Grow in={isOpenDialog} style={{ transformOrigin: "0 0 0" }} {...(isOpenDialog ? { timeout: 600 } : {})}>
            <IconButton
              title='Blur Filter'
              color='primary'
              className='navButton'
              disabled={loading}
              style={{ width: "1.5em", height: "1.5em" }}
              onClick={() => executeFilter(undefined, BackgroundType.Blur)}>
              <BlurOn></BlurOn>
            </IconButton>
          </Grow>
          <Grow in={isOpenDialog} style={{ transformOrigin: "0 0 0" }} {...(isOpenDialog ? { timeout: 800 } : {})}>
            <IconButton
              color='secondary'
              title='Remove Filter'
              className='navButton'
              style={{ width: "1.5em", height: "1.5em" }}
              onClick={() => {
                props.setIsActiveFilter(false);
                props.removeVirtualFilter();
              }}>
              <Block></Block>
            </IconButton>
          </Grow>
        </>
      )}
    </div>
  );
}
