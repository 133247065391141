import React, { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";

import { VideoConferenceContainer } from "./pages/Conference";

const container = document.getElementById("root");
const root = createRoot(container!);

root.render(
  <StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path='/home/:session_id' element={<VideoConferenceContainer />} />
        <Route path='/:session_id' element={<Navigate replace to='/home/:session_id' />} />
      </Routes>
    </BrowserRouter>
  </StrictMode>
);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
