import './FinishingComponent.css';

export default function FinishingComponent() {
  return (
    <div className="finished-overlay">
      <div className="finishedInfo">
        <h2>Vistoria concluída</h2>
      </div>
    </div>
  );
}
