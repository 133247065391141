import { Toolbar } from "@material-ui/core";
import "./Toolbar.css";

import MicButton from "./MicButton";
import LeaveButton from "./LeaveButton";
import CameraButton from "./CameraButton";
import CaptureButton from "./CaptureButton";

export function ToolbarComponent({
  session,
  publisher,
  activeAudio,
  activeVideo,
  setIsMicActive,
  setIsCamActive,
}: any) {
  return (
    <div className='controls'>
      <Toolbar>
        <div className='buttonsContent'>
          <MicButton publisher={publisher} isMicActive={activeAudio} setIsMicActive={setIsMicActive} />
          <CameraButton publisher={publisher} isCamActive={activeVideo} setIsCamActive={setIsCamActive} />
          <CaptureButton publisher={publisher} sessionId={session?.sessionId} />
          <LeaveButton session={session} />
        </div>
      </Toolbar>
    </div>
  );
}
