import { OpenVidu, Publisher, PublisherProperties, Session } from "openvidu-browser";
import { IRTCProvider } from "shared/interfaces";

export class OpenViduProvider implements IRTCProvider {
  private ov: OpenVidu;
  constructor() {
    this.ov = new OpenVidu();
  }

  initializeSession(): Session {
    this.ov.setAdvancedConfiguration({
      iceConnectionDisconnectedExceptionTimeout: 4000,
      forceMediaReconnectionAfterNetworkDrop: true,
      noStreamPlayingEventExceptionTimeout: 5000,
    });
    return this.ov.initSession();
  }

  initializePublisher(
    element: HTMLElement | string | undefined,
    options: PublisherProperties,
    callback?: any
  ): Publisher {
    return this.ov.initPublisher(element!, options, callback);
  }
}
