import { useHotkeys } from "react-hotkeys-hook";
import { AddAPhoto } from "@material-ui/icons";

import { Button } from "@material-ui/core";
import { SignalEvent } from "openvidu-browser";
import { useState } from "react";
import { STORAGE_HOST, APP_DOMAIN } from "shared/constants";
import { Signals } from "shared/enums";
import { CaptureAsset } from "shared/interfaces";
import { v4 } from "uuid";

export default function CaptureButton(props: { sessionId: string; publisher: any }) {
  const [imageUploads, setImageUpload] = useState<CaptureAsset[]>([]);

  const { publisher } = props;

  function createImageRequest() {
    const currentCapture = {
      id: v4(),
      progress: 0,
      hasThumb: false,
      isFailed: false,
      isConfirmed: false,
      isSubscribed: false,
      createdAt: new Date(),
    };

    publisher.session.signal({
      data: currentCapture.id,
      type: Signals.ScreenShot,
    });

    publisher.session.on(`signal:${Signals.Upload}:${currentCapture.id}`, (event: SignalEvent) => {
      currentCapture.isSubscribed = true;
      currentCapture.progress = Number(event.data);
      console.log(`signal:${Signals.Upload}:${currentCapture.id} - loading ${event.data}%`);
      updateItemCapture(currentCapture);
    });

    setImageUpload((current: CaptureAsset[]) => [...current, currentCapture]);
    return currentCapture;
  }

  function updateItemCapture(currentCapture: CaptureAsset) {
    const items = imageUploads.filter((item) => item.id !== currentCapture.id);
    setImageUpload([...items, currentCapture]);
  }

  function subscribeToConfirmation(currentCapture: CaptureAsset) {
    publisher.session.on(`signal:${Signals.ScreenShot}:${currentCapture.id}`, async (event: SignalEvent) => {
      console.info(`${event.data} frame has been confirmed`, { event, now: new Date() });

      currentCapture.isConfirmed = true;
      const items = imageUploads.filter((item) => item.id !== currentCapture.id);
      setImageUpload([...items, currentCapture]);

      setTimeout(async () => {
        const capturePath = `${STORAGE_HOST}/${event.data}-thumb.jpg`;
        const confirmation: Response = await fetch(capturePath);
        const timeout = confirmation?.status === 200 ? 2000 : 1500;

        if (confirmation?.status === 200) {
          currentCapture.hasThumb = true;
          updateItemCapture(currentCapture);

          console.log({ confirmation, timeout });
          window.parent.postMessage(
            {
              func: "loadGallery",
              message: publisher.session.sessionId,
            },
            APP_DOMAIN!
          );
        } else {
          const subscribeToThumbs = setInterval(async () => {
            const response: Response = await fetch(capturePath);
            console.log({ response, timeout });
            if (response?.status === 200) {
              currentCapture.hasThumb = true;
              updateItemCapture(currentCapture);
              clearInterval(subscribeToThumbs);
              window.parent.postMessage(
                {
                  func: "loadGallery",
                  message: publisher.session.sessionId,
                },
                APP_DOMAIN!
              );
            }
          }, 1500);
        }
      }, 2500);
    });
  }

  function subscribeToException(id: string) {
    publisher.session.on(`signal:${Signals.ScreenShot}:${id}:failed`, (event: SignalEvent) => {
      console.info(`${event.data} frame was not confirmed`, { event, now: new Date() });

      const currentCapture = imageUploads.find((current) => current.id === id);

      console.log({ imageUploads, currentCapture });
      if (currentCapture) {
        currentCapture.isFailed = true;
        updateItemCapture(currentCapture);
      }
    });
  }

  function remoteCapturePhotoFromStream() {
    // TODO: include isReady control
    const currentCapture = createImageRequest();

    subscribeToException(currentCapture.id);
    subscribeToConfirmation(currentCapture);

    console.log({ currentCapture });
  }

  useHotkeys("shift+c", () => {
    remoteCapturePhotoFromStream();
  });

  return (
    <>
      <div>
        <Button
          color='primary'
          title='CAPTURAR'
          variant='contained'
          className='navButton'
          id='navCaptureButton'
          startIcon={<AddAPhoto />}
          onClick={remoteCapturePhotoFromStream}
        />
      </div>
    </>
  );
}
