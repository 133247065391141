import { MicOff, Mic } from '@material-ui/icons';
import { IconButton } from '@material-ui/core';
import { Publisher } from 'openvidu-browser';
import './Buttons.css';

export default function MicButton(props: {
  publisher: Publisher;
  isMicActive: boolean;
  // eslint-disable-next-line @typescript-eslint/ban-types
  setIsMicActive: Function;
}) {
  function micStatusChanged() {
    console.log('publishAudio', !props.isMicActive);
    props.publisher.publishAudio(!props.isMicActive);
    props.setIsMicActive(!props.isMicActive);
  }

  return (
    <IconButton color="inherit" id="navMicButton" className="navButton" onClick={micStatusChanged}>
      {props.isMicActive ? <Mic /> : <MicOff color="secondary" />}
    </IconButton>
  );
}
