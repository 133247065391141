export enum EventType {
  SELECT_CONTENT = "select_content",
  LEVEL_START = "level_start",
  LEVEL_END = "level_end",
  PAGE_VIEW = "page_view",
  EXCEPTION = "exception",
}

export enum Roles {
  Analyst = "PERITO",
  Insured = "SEGURADO",
}

export enum BackgroundType {
  GStreamer = "GStreamerFilter",
  Image = "VB:image",
  Blur = "VB:blur",
}

export enum SessionStatus {
  Closed = "closed", // session is over
  Active = "active", // session is active ( all participants connected )
  Awaiting = "awaiting", // session is not active ( missing participant )
}

export enum ConnectionStatus {
  Connected = "connected",
  Disconnected = "disconnected",
}

// https://docs.openvidu.io/en/stable/advanced-features/network-quality/
export enum NetworkStatus {
  Broken = "Péssima",
  Bad = "Muito intermitente",
  Poor = "Pouco intermitente",
  NonOptimal = "Regular",
  Good = "Ótima",
  Excellent = "Excelente",
}

export enum Signals {
  Upload = "upload",
  ScreenShot = "screenshot",
  UserChanged = "userChanged",
  MessagingAnalyst = "messaging-analyst",
  MessagingInsured = "messaging-insured",
}

export enum ConferenceMode {
  OnlyVideo = "onlyVideo",
  OnlyAudio = "onlyAudio",
  AudioAndVideo = "audioAndVideo",
}

export enum Environments {
  HOMOLOG = "homolog",
  PRODUCTION = "production",
  DEVELOPMENT = "development",
}

export enum ConnectionStatus {
  RECONNECTING = "reconnecting",
  DISCONNECTED = "disconnected",
  RECONNECTED = "reconnected",
  CONNECTED = "connected",
}
