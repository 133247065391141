import { useEffect, useMemo, useState } from "react";
import { createSession } from "services/managerService";
import { DEFAULT_DEVICE_OPTIONS, AUDIO_ONLY_DEVICE_OPTIONS } from "shared/constants";

import {
  StreamEvent,
  Subscriber,
  Publisher,
  Session,
  StreamManager,
  ExceptionEvent,
  SessionDisconnectedEvent,
  StreamPropertyChangedEvent,
} from "openvidu-browser";

import useOSNetwork from "hooks/useOSNetwork";
import { IRTCProvider } from "shared/interfaces";

import usePublisherFilters from "./usePublisherFilters";
import { OpenViduProvider } from "providers/OpenVidu";
import { Roles, ConferenceMode } from "shared/enums";

// TODO: (1|2) setup core objects ( session | publisher )
export default function useRTCRoom(props: {
  companyId: string;
  sessionId: string;
  setSubscribers: any;
  setIsActiveAudio: any;
  isActiveAudio: boolean;
  handleErrorCallback: any;
  availableCameras: MediaDeviceInfo[];
  availableMicrophones: MediaDeviceInfo[];
  defaultCamera: MediaDeviceInfo | number;
  defaultMicrophone: MediaDeviceInfo | number;
}) {
  const [isReady, setReadyness] = useState(false);
  const [session, setSession] = useState<Session>(null!);
  const [publisher, setPublisher] = useState<Publisher>(null!);

  const [isRecovering, setIsRecovering] = useState(false);
  const [isConnecting, setIsConnecting] = useState(false);

  const [recoverException, setRecoverException] = useState<any>(null);
  const [mode, setConferenceMode] = useState<ConferenceMode>(null!);
  const openviduProvider: IRTCProvider = useMemo(() => new OpenViduProvider(), []);

  const { sessionId, companyId, setSubscribers } = props;
  const { defaultCamera, defaultMicrophone, handleErrorCallback } = props;

  const { isOnline } = useOSNetwork();
  const { applyVirtualFilter, removeVirtualFilter } = usePublisherFilters({
    publisher,
  });

  async function subscribeToSessionEvents(session: Session) {
    session.on("streamCreated", (event: StreamEvent) => {
      const { clientData } = JSON.parse(event.stream.connection.data);
      console.log({ clientData, stream: event.stream.streamId });

      if (clientData === Roles.Insured) {
        // const subscriber: Subscriber = session.subscribe(event.stream, undefined!);
        const subscriber: Subscriber = session.subscribe(event.stream, "video-remote", {
          insertMode: "APPEND",
        });

        // TODO: include interface with props ( isPlaying )
        console.info({ subscriber });
        setSubscribers((current: StreamManager[]) => [...current, subscriber]);
      }
    });

    session.on("streamDestroyed", (event: StreamEvent) => {
      const { clientData } = JSON.parse(event.stream.connection.data);
      console.log({ clientData, stream: event.stream.streamId });

      if (clientData === Roles.Insured) {
        setSubscribers((current: StreamManager[]) => [
          ...current.filter((a) => a.stream.connection.connectionId !== event.stream.connection.connectionId),
        ]);
      }
    });

    // https://docs.openvidu.io/en/stable/api/openvidu-browser/classes/SessionDisconnectedEvent.html
    session.on("sessionDisconnected", (event: SessionDisconnectedEvent) => {
      console.log(`[sessionDisconnected]`, event);

      if (event.reason === "networkDisconnect") {
        // props.setConnectionStatus('disconnected');

        console.log({ isRecovering, recoverException, publisher });
        setIsRecovering(true);
        setRecoverException({
          name: "ApplicationCustomError",
          message: "Segurado: Perda de conexão de rede",
          origin: "sessionDisconnected.networkDisconnect",
        });
      }

      if (event.reason === "disconnect") {
        console.log({ isRecovering, recoverException, publisher });
        setIsRecovering(true);
      }
    });

    session.on("streamPropertyChanged", (event: StreamPropertyChangedEvent) => {
      console.log("[streamPropertyChanged]", {
        event,
      });
    });

    // TODO: https://github.com/OpenVidu/openvidu.io-docs/blob/master/docs/advanced-features/automatic-reconnection.md
    session.on("exception", async (exception: ExceptionEvent) => {
      console.warn(exception);
      // let isFatalException;

      switch (exception.name) {
        case "ICE_CANDIDATE_ERROR":
          // isFatalException = true;
          console.warn(exception);
          break;
        case "ICE_CONNECTION_FAILED":
          console.warn("Stream " + exception.origin + " broke!");
          console.warn("Reconnection process automatically started");
          break;
        case "ICE_CONNECTION_DISCONNECTED":
          console.warn("Stream " + exception.origin + " disconnected!");
          console.warn("Giving it some time to be restored. If not possible, reconnection process will start");
          break;
        case "NO_STREAM_PLAYING_EVENT":
          try {
            if (exception.origin instanceof Subscriber) {
              const subscriber: Subscriber = exception.origin;
              // session.unsubscribe(subscriber);

              // TODO: remove connection from server
              setSubscribers((current: StreamManager[]) => [
                ...current.filter((a) => a.stream.connection.connectionId !== subscriber.stream.connection.connectionId),
              ]);

              console.warn({ subscriber, exception });
            }
          } catch (error) {
            console.warn(exception);
          }
          break;
      }
    });
  }

  async function connectSession(session: Session, publisher: Publisher) {
    try {
      const { token } = await createSession(sessionId, companyId);

      await session.connect(token, { clientData: Roles.Analyst });
      await session.publish(publisher);
      setReadyness(true);
    } catch (error) {
      handleErrorCallback(error);
    }
  }

  useEffect(() => {
    console.log({ publisher, defaultCamera, defaultMicrophone });

    // TODO: refer to audio|video available option
    if (!publisher) {
      if (defaultCamera === 0 && defaultMicrophone) {
        setConferenceMode(ConferenceMode.OnlyAudio);
        const deviceConfig = { ...AUDIO_ONLY_DEVICE_OPTIONS };
        console.log({ deviceConfig });

        const pub = openviduProvider.initializePublisher(undefined, deviceConfig);
        setPublisher(pub);
      } else if (defaultCamera && defaultMicrophone) {
        setConferenceMode(ConferenceMode.AudioAndVideo);
        const deviceConfig = { ...DEFAULT_DEVICE_OPTIONS };
        console.log({ deviceConfig });

        const pub = openviduProvider.initializePublisher(undefined, deviceConfig);
        setPublisher(pub);
      }
    }
  }, [publisher, defaultCamera, defaultMicrophone]);

  // TODO: specify properties from valid Session instead of nullability validation
  useEffect(() => {
    if (isOnline && sessionId && companyId && !session) {
      try {
        // session && props.hasVideoAvailable && isConnecting && !publisher
        const session = openviduProvider.initializeSession();
        subscribeToSessionEvents(session);

        setSession(session);
        setIsConnecting(true);
      } catch (error: unknown) {
        handleErrorCallback(error);
      }
    }
  }, [session, isOnline, sessionId, companyId]);

  useEffect(() => {
    if (isConnecting && session && publisher) {
      const connect = async () => {
        try {
          await connectSession(session, publisher);
          setIsConnecting(false);
        } catch (error) {
          console.warn(error);
        }
      };

      connect();
    }
  }, [isConnecting, session, publisher]);

  useEffect(() => {
    console.log({ recoverException, isRecovering, isOnline });
    if (recoverException && isRecovering && isOnline) {
      const fallback = () => {
        setTimeout(async () => {
          await connectSession(session, publisher);
          setRecoverException(null);
          setIsRecovering(false);
        }, 1500);
      };

      fallback();
    }
  }, [recoverException, isRecovering, isOnline]);

  return {
    mode,
    isReady,
    session,
    isOnline,
    publisher,
    isRecovering,
    recoverException,
    applyVirtualFilter,
    removeVirtualFilter,
  };
}
