import "./AwaitingComponent.css";

export function AwaitingComponent(props: { message?: string }) {
  return (
    <div className='overlay'>
      <div className='waitInfo'>
        <h2>{props.message || "Aguardando a conexão do cliente"}</h2>
      </div>
    </div>
  );
}
