import { ErrorBoundary } from "react-error-boundary";
import { ErrorFallback } from "./ErrorFallback";
import { EventType } from "../../shared/enums";

export default function ErrorBoundaryComponent(props: { container: any; onResetState: any }) {
  return (
    <ErrorBoundary
      FallbackComponent={ErrorFallback}
      onError={(error, info) => {
        console.error(EventType.EXCEPTION, {
          description: `${error.name} | ${error.message} | \n ${info}`,
          fatal: true,
        });
      }}
      onReset={() => {
        props.onResetState();
      }}>
      {props.container}
    </ErrorBoundary>
  );
}
