import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { Modal } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import './ModalDialogComponent.css';

export default function ModalDialogComponent(props: any) {
  return (
    <Modal open={props.isOpenDialog} onClose={props.cancelLeaveSession}>
      <Card id="card">
        <CardContent>
          <Typography color="textSecondary">
            Você têm certeza que deseja finalizar essa chamada?
          </Typography>
        </CardContent>
        <CardActions>
          <Button size="small" onClick={props.confirmLeaveSession}>
            Sim
          </Button>

          <Button size="small" onClick={props.cancelLeaveSession}>
            Não
          </Button>
        </CardActions>
      </Card>
    </Modal>
  );
}
