import { useEffect, useState } from 'react';


export function useCameraDevices() {
  
  const [defaultCamera, setDefaultCamera] = useState<MediaDeviceInfo|number>(null!);
  const [availableCameras, setAvailableCameras] = useState<MediaDeviceInfo[]>([]);
 
  function filterUsableDevice(exp: RegExp, device: MediaDeviceInfo) {
    return device.label.match(exp) && device.kind === 'videoinput';
  }

  useEffect(()=>{
    const localCameraDevice = async()=>{
      if(!defaultCamera){

        try {
          await navigator.mediaDevices.getUserMedia({
            video: {
              facingMode: 'environment',
            }
          }); 

        } catch (error) {
          console.log(error);
        }

        const devices = await navigator.mediaDevices.enumerateDevices();
        const videoDevices = devices.filter((device) => filterUsableDevice(/^.{4,35}$/i, device));

        console.log({devices, videoDevices});

        setAvailableCameras(videoDevices);

        if(videoDevices.length > 0){
          setDefaultCamera(videoDevices[0]);
        }else{
          setDefaultCamera(0);
        }
        
      }
    }

    localCameraDevice();
  },[defaultCamera]);

  return {
    defaultCamera,
    availableCameras
  };
}
